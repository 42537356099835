import React from "react"

const Banner = props => {
  const { backgroundColor, text, image, roundal } = props.content
  return (
    <section
      className={`banner banner--no-background-img banner--${backgroundColor}`}
    >
      <p>
        <q>{text}</q>
      </p>
      <img src={image} alt="" />
      <div className="roundal-container">
        <div className="roundal">
          <span>{roundal}</span>
        </div>
      </div>
    </section>
  )
}

export default Banner
