import React, { Component } from "react"
import { Link } from "gatsby"
import Sticker from "react-stickyfill"

class TextBlock extends Component {
  constructor(props) {
    super(props)
    this.state = { stickyFinished: false, intervalId: 0 }
    this.handleFade = this.handleFade.bind(this)
    this.stickyElem = React.createRef()
  }

  handleFade() {
    const intervalId = setInterval(() => {
      const stickyElem = document.querySelector(
        ".article-text-block__product img"
      )
      if (!stickyElem) return

      const stickyElemPos = this.stickyElem.current.getBoundingClientRect().top

      if (stickyElemPos < -150) {
        this.setState({ stickyFinished: true })
      } else {
        this.setState({ stickyFinished: false })
      }
    }, 250)

    this.setState({ intervalId: intervalId })
  }
  componentDidMount() {
    this.handleFade()
  }
  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }
  render() {
    const { text, product, productLink } = this.props.content
    return (
      <>
        <div className="article-text-block">
          <div className="article-text-block__text">{text}</div>
          <div className="article-text-block__product">
            <Sticker>
              <div>
                <Link to={productLink}>
                  <img
                    src={product}
                    alt=""
                    ref={this.stickyElem}
                    className={this.state.stickyFinished ? "fade-out" : ""}
                  />
                </Link>
              </div>
            </Sticker>
          </div>
        </div>
      </>
    )
  }
}

export default TextBlock
