import React from "react"
import TextBlock from "../components/global/article/textBlock"
import Banner from "../components/global/article/banner"
import BannerImageWithText from "../components/global/article/bannerWithText"
import Layout from "../components/global/layout"
import headerBannerImage from "../images/blistex-article-banner.jpg"
import brickBanner from "../images/brick-banner.jpg"
import moisturiserProduct from "../images/product-and-package/inm.jpg"
import medPlusProduct from "../images/product-and-package/mp.jpg"
import ultraProtect from "../images/product-and-package/ul.jpg"
import crackedLipsImage from "../images/cracked-lips-image.png"
import paginationBanner from "../images/wind-burn-article.jpg"
import Header from "../components/global/header"
import SEO from "../components/global/seo"

const tempData = {
  hero: {
    image: headerBannerImage,
    text: (
      <>
        <h1 className="double-line">
          <span className="large-letter">W</span>inter lips that conquer the
          cold.
        </h1>
      </>
    ),
    align: "left",
  },
  firstComponent: {
    product: moisturiserProduct,
    productLink: "/intensive-moisturiser/",
    text: (
      <>
        <p>
          at Blistex we’re big believers in loving your lips, whatever the
          season. cold weather can cause lips to dry out and chap, making winter
          the time to pay extra attention to keeping lips healthy.
        </p>
        <p>with Blistex, your lips can conquer the cold.</p>
        <h2>poor old lips</h2>
        <p>
          it’s not their fault. It’s just that your lips are thinner than the
          rest of your skin and have far less glands. there’s no melanin to
          protect them from rays of sunshine either.
        </p>
        <p>
          unprotected when you’re out and about, or battling with air con and
          central heating indoors, lips need moisture.
        </p>
        <p>
          when your lips become dry, cracked, sore or infected, show them some
          tender loving care and attention.
        </p>
      </>
    ),
  },
  secondComponent: {
    image: brickBanner,
    text: (
      <>
        <h2>
          remedies for
          <span className="large">
            cracked <br /> & chapped
          </span>
        </h2>
        <h3>effective solutions to prevent dry lips</h3>
      </>
    ),
    align: "left",
  },
  thirdComponent: {
    product: ultraProtect,
    productLink: "/ultra-lip-balm/",
    text: (
      <>
        <h2>bring back the love </h2>
        <p>
          there are a few ways to make sure your lips get the moisture they need
          in cold weather.
        </p>

        <p>
          <strong>drink plenty of water.</strong> perhaps the simplest cure
          there is and one we’ve all heard before. hydration is key to
          preventing lips from cracking so grab your water bottle. if you prefer
          not to drink cold water in the depths of winter, try a healthy hot
          water with a cleansing twist of lemon.
        </p>

        <p>
          <strong>moisturise.</strong> using Blistex Intensive Moisturiser will
          bring deep rehydration to lips. we’ve combined shea butter to
          condition, camphor to soothe and allantoin to add a layer of
          protection. apply as ofen as you like to keep lips sweet and soft.
        </p>

        <p>
          <strong>don’t lick your lips.</strong> it’s tempting when your lips
          feel dry but you’re actually making things worse. keeping your Blistex
          handy will help to squash the urge and give lips the kind of moisture
          that does them good.
        </p>
      </>
    ),
  },
  forthComponent: {
    text:
      "the skin on our lips is much thinner than other parts of the body so it’s imperitive that they’re looked after",
    image: crackedLipsImage,
    roundal: "the lip experts",
    backgroundColor: "black",
  },
  fifthComponent: {
    product: ultraProtect,
    productLink: "/ultra-lip-balm/",
    text: (
      <>
        <h2>ski, sun, lips</h2>

        <p>
          if you’re off on a skiing trip over winter, put Blistex at the top of
          your packing list. The combination of wind, sun and cold on lips can
          be intense, so be prepared.
        </p>
        <p>
          make sure you’ve got sunscreen with a high SPF for your face, Blistex
          to protect your lips and a scarf to protect any exposed skin on your
          neck.
        </p>
        <p>
          if you start to get a burning feeling on your skin, it could be
          windburn. steer clear of hot showers and drinks and apply moisture to
          your face and lips.
        </p>
        <p>
          with over 70 years of experience, we know that when the cold hits it’s
          time to take lips seriously. Blistex is here to help you do just that.
        </p>
      </>
    ),
  },
  sixthComponent: {
    image: paginationBanner,
    articleLink: "/wind-burn",
    text: (
      <>
        <h2 className="small">windburn</h2>
        <h2 className="large">up next.</h2>
      </>
    ),
    align: "center",
  },
}

const Article = () => {
  return (
    <>
      <SEO title="Winter lips that conquer the cold." />
      <Layout noHeaderInLayout={true} makeFooterSticky={false} whiteMenu={true}>
        <div className="header-hero-container header-hero-container--banner">
          <Header />
          <BannerImageWithText content={tempData.hero} />
        </div>

        <TextBlock content={tempData.firstComponent} />
        <BannerImageWithText content={tempData.secondComponent} />
        <TextBlock content={tempData.thirdComponent} />
        <Banner content={tempData.forthComponent} />
        <TextBlock content={tempData.fifthComponent} />
        <BannerImageWithText content={tempData.sixthComponent} />
      </Layout>
    </>
  )
}

export default Article
