import React, { useEffect } from "react"
import { Link } from "gatsby"
import objectFitImages from "object-fit-images"

const BannerImageWithText = props => {
  const { image, text, align } = props.content
  const color = props.color ? props.color : "white"
  let timeoutId = 0

  useEffect(() => {
    objectFitImages()
  })
  return (
    <div className={`banner banner--${align}`}>
      {props.content.articleLink && (
        <Link to={props.content.articleLink}>
          <img src={image} alt="" />
          <div className={"banner__text banner__text--" + color}>{text}</div>
        </Link>
      )}
      {!props.content.articleLink && (
        <>
          <img src={image} alt="" />
          <div className={"banner__text banner__text--" + color}>{text}</div>
        </>
      )}
    </div>
  )
}

export default BannerImageWithText
